import React from "react";
import Image from "next/image";
import Link from "next/link";
import { RouterConfig } from "@shared/lib/routerConfig";
import classes from "./analytics-banner.module.scss";

export const AnalyticsBanner = () => {
    return (
        <Link href={RouterConfig.ANALYTICS} className={classes["analytics-banner"]}>
            <div className={classes["analytics-banner__top"]}>
                <Image
                    className={classes["analytics-banner__icon"]}
                    src={"/img/analytics/analytics-icon.webp"}
                    alt={"Оценка авто - иконка"}
                    width={60}
                    height={75}
                    unoptimized
                />
                <span>Узнайте, сколько стоит автомобиль</span>
            </div>
            <div className={classes["analytics-banner__bottom"]}>
                <Image
                    src={"/img/analytics/analytics-banner.webp"}
                    alt={"Оценка авто - автомобили"}
                    width={260}
                    height={90}
                    unoptimized
                />
                <div className={classes["analytics-banner__action"]}>Оценка автомобиля</div>
            </div>
        </Link>
    );
};